import React, { Component } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink, FieldSelect } from '../../components';
import { TopbarSearchForm } from '../../forms';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import css from './SectionHero.css';

class SectionHero extends Component {
  constructor(props) {
    super(props);

    this.categorySelecting = this.categorySelecting.bind(this);
  }

  categorySelecting(e) {
    const { history } = this.props;
    const searchParams = {
      pub_category: e.target.value,
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  }

  render() {

    const { rootClassName, className, categories } = this.props;
    const classes = classNames(rootClassName || css.root, className);
   
    return (
      <div className={classes}>
        <div className={css.heroContent}>
          <h1 className={css.heroMainTitle}>
            <FormattedMessage id="SectionHero.title" />
          </h1>
          <div className={css.buttons}>
            <div className={css.buttonWrapper}>
              <h3 className={css.buttonTitle}>
                <FormattedMessage id="SectionHero.browseButtonTitle" />
              </h3>
              {/* <NamedLink
                name="SearchPage"
                to={{
                  search:
                    'address=Finland&bounds=70.0922932%2C31.5870999%2C59.693623%2C20.456500199999937',
                }}
                className={css.heroButton}
              >
                <FormattedMessage id="SectionHero.browseButton" />
              </NamedLink> */}
              <select
                className={css.category}
                name={"category"}
                id={"category"}
                onChange={e => this.categorySelecting(e)}
              >
                <option value="">
                  {`Choose category`}
                </option>
                {categories.map(c => (
                  <option key={c.key} value={c.key}>
                    {c.label}
                  </option>
                ))}
              </select>
            </div>
            <div className={css.buttonWrapper}>
              <h3 className={css.buttonTitle}>
                <FormattedMessage id="SectionHero.listingButtonTitle" />
              </h3>
              <NamedLink
                name="NewListingPage"
                className={css.heroButton}
              >
                <FormattedMessage id="SectionHero.listingButton" />
              </NamedLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

SectionHero.defaultProps = { 
  rootClassName: null, 
  className: null,
  categories: config.custom.categories,
};

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
