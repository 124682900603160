const CUSTOMER_COMMISSION_PERCENTAGE = process.env.REACT_APP_CUSTOMER_COMMISSION_PERCENTAGE;

const CUSTOMER_COMMISSION_DEFAULT = 11;
const CUSTOMER_COMMISSION_HST = 13;

const commissionForCalc = (commission) => {
    return Math.round((commission / CUSTOMER_COMMISSION_PERCENTAGE) * 100)
}

export const calcCommission = (amount) => {

    const defaultCommissionPercent = commissionForCalc(CUSTOMER_COMMISSION_DEFAULT);
    const hstCommissionPercent = commissionForCalc(CUSTOMER_COMMISSION_HST);

    const defaultCommission = (amount * defaultCommissionPercent) / 100;
    const hstCommission = (amount * hstCommissionPercent) / 100;

  return { defaultCommission: defaultCommission, hstCommission: hstCommission };
};
