/**
 * This is a wrapper component for different Layouts. Footer content should be added to this wrapper.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './LayoutWrapperFooter.css';


class LayoutWrapperFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFooterOpen: false,
    }
    this.handleShow = this.handleShow.bind(this);
  }

  handleShow() {
    this.setState({
      isFooterOpen: !this.state.isFooterOpen
    })
  }

  render() {
    const { className, rootClassName, children } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const { isFooterOpen } = this.state;
    const footer = isFooterOpen ? (
      <div className={css.footerContainer}>
        {children}
      </div>
    ) : null;

    const button = isFooterOpen ? (
      <Button onClick={this.handleShow} className={css.footerButtonMore}>
        <FormattedMessage id="Footer.footerButtonClose" />
      </Button>
    ) : (
      <Button onClick={this.handleShow} className={css.footerButtonMore}>
        <FormattedMessage id="Footer.footerButtonMore" />
      </Button>
    );

    const categoriesLinks = (
      <>
        <NamedLink
          name="SearchPage"
          to={{
            search:
            'pub_category=accommodations',
          }}
          className={css.footerCategoryLink}
        >
          <FormattedMessage id="Footer.footerButtonAccommodation" />
        </NamedLink>

        <NamedLink
          name="SearchPage"
          to={{
            search:
            'pub_category=equipment',
          }}
          className={css.footerCategoryLink}
        >
          <FormattedMessage id="Footer.footerButtonEquipmentRentals" />
        </NamedLink>

        <NamedLink
          name="SearchPage"
          to={{
            search:
            'pub_category=adventures',
          }}
          className={css.footerCategoryLink}
        >
          <FormattedMessage id="Footer.footerButtonAdventures" />
        </NamedLink>
      </>
    )

    return(
      <div className={classes}>
        <div className={css.footerButtonsHolder}>
          {categoriesLinks}
          {button}
        </div>
        {footer}
      </div>
    ) 
  }
};

LayoutWrapperFooter.defaultProps = {
  className: null,
  rootClassName: null,
};

const { node, string } = PropTypes;

LayoutWrapperFooter.propTypes = {
  children: node.isRequired,
  className: string,
  rootClassName: string,
};

export default LayoutWrapperFooter;
