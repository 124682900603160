/*
 * Marketplace specific configuration.
 */

export const amenities = [
  {key: "ac", label: "A/C"},
  {key: "bbq", label: "BBQ"},
  {key: "bed_linens", label: "Bed linens supplied"},
  {key: "cable", label: "Cable"},
  {key: "dvd", label: "DVD player"},
  {key: "fire_pit", label: "Fire pit"},
  {key: "fireplace_gas", label: "Fireplace (Gas)"},
  {key: "fireplace_wood", label: "Fireplace (Wood burning)"},
  {key: "football", label: "Foosball table"},
  {key: "parking", label: "Free parking"},
  {key: "heat", label: "Heat"},
  {key: "heated_floors", label: "Heated floors"},
  {key: "hot_tub", label: "Hot tub"},
  {key: "lake_view", label: "Lake view"},
  {key: "mountain_view", label: "Mountain view"},
  {key: "patio", label: "Patio"},
  {key: "pet_friendly", label: "Pet friendly"},
  {key: "ping_pong", label: "Ping pong table"},
  {key: "pool", label: "Pool"},
  {key: "pool_table", label: "Pool table"},
  {key: "beach", label: "Private Beach Access"},
  {key: "sauna", label: "Sauna"},
  {key: "shuttle_service", label: "Shuttle service to and from the Resort"},
  {key: "smart_tv", label: "Smart TV"},
  {key: "sunroom", label: "Sunroom"},
  {key: "tv", label: "TV"},
  {key: "towels", label: "Towels supplied"},
  {key: "washer_and_dryer", label: "Washer and Dryer"},
  {key: "wet_bar", label: "Wet bar"},
  {key: "wifi", label: "WiFi"},
];

export const categories = [
  {key: 'accommodations', label: 'Accommodations'},
  {key: 'equipment', label: 'Equipment rentals'},
  {key: 'adventures', label: 'Adventures'},
];

export const accommodations = [
  {key: "chalet", label: "Chalet"},
  {key: "condo", label: "Condo"},
  {key: "town_house", label: "Town House"},
];

export const equipment = [
  {key: "bikes", label: "Bikes"},
  {key: "boats", label: "Boats"},
  {key: "canoes", label: "Canoes"},
  {key: "kayaks", label: "Kayaks"},
  {key: "seadoo", label: "Seadoo"},
  {key: "skis", label: "Ski's"},
  {key: "snow_shoes", label: "Snow shoes"},
  {key: "snowboards", label: "Snowboards"},
  {key: "stand_boards", label: "Stand up boards"},
];

export const adventures = [
  {key: "beer_tours", label: "Beer Tours"},
  {key: "humvee_tours", label: "Humvee Tours"},
  {key: "kayak_tours", label: "Kayak Tours"},
  {key: "shoes_hikes", label: "Snow Shoes Hikes"},
  {key: "spa_booking", label: "Spa Bookings"},
  {key: "board_tours", label: "Stand up Board Tours"},
  {key: "wine_tours", label: "Wine Tours"},
  {key: "axe_throwing", label: "Axe Throwing"},
];

export const subcategories = {
  accommodations: accommodations,
  equipment: equipment,
  adventures: adventures,
};

export const defaultLocation = 'Blue Mountain, Ontario, Canada';

// Price filter configuration
// Note: unlike most prices this is not handled in subunits
export const priceFilterConfig = {
  min: 0,
  max: 1000,
  step: 5,
};

// Activate booking dates filter on search page
export const dateRangeFilterConfig = {
  active: true,
};

// Activate keyword filter on search page

// NOTE: If you are ordering search results by distance the keyword search can't be used at the same time.
// You can turn off ordering by distance in config.js file
export const keywordFilterConfig = {
  active: true,
};
