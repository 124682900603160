import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput } from '../../components';

import ManageAvailabilityCalendar from './ManageAvailabilityCalendar';
import css from './EditListingAvailabilityForm.css';

import { CusPriceForm } from '../../containers/EditCusPriceDialog/CusPriceForm';

import * as cusPriceModel from '../../containers/EditCusPriceDialog/CusPriceDialog.duck'

import { types as sdkTypes } from '../../util/sdkLoader';
import { composeValidators, required } from "../../util/validators";
export class EditListingAvailabilityFormComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showPriceDialog: false,
      cusPriceDate: null
    };

    this.onShowCusPriceBtnClick = this.onShowCusPriceBtnClick.bind(this);
    this.onCloseCusPriceBtnClick = this.onCloseCusPriceBtnClick.bind(this);
  }

  onShowCusPriceBtnClick() {
    return (date) => {
      this.setState({
        showPriceDialog: true,
        cusPriceDate: date
      })
    }
  }

  onCloseCusPriceBtnClick() {
    this.setState({
      showPriceDialog: false,
      cusPriceDate: null
    })
  }

  render() {

    const {
      intl,
      listingId,
      currentListing,
      onManageDisableScrolling,
    } = this.props;

    let currentCusPrice = this.state.cusPriceDate && cusPriceModel.findCusPriceByDate(
      this.state.cusPriceDate,
      this.props.currentListing.attributes.publicData.customPrices
    );

    if (this.state.cusPriceDate &&
        !currentCusPrice &&
        cusPriceModel.isWeekend(this.state.cusPriceDate)) {
      currentCusPrice = cusPriceModel.buildCusPriceRecord(this.state.cusPriceDate, 'weekend_rate');
    }

    return (
      <div>
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            className,
            rootClassName,
            disabled,
            handleSubmit,
            //intl,
            invalid,
            pristine,
            saveActionMsg,
            updated,
            updateError,
            updateInProgress,
            availability,
            availabilityPlan,
            listingId,
            currentListing,
            onManageDisableScrolling,
          } = fieldRenderProps;

          const errorMessage = updateError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingAvailabilityForm.updateFailed" />
            </p>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitReady = updated && pristine;
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || disabled || submitInProgress;

          // iCal link
          const linkMessage = intl.formatMessage({ id: 'EditListingAvailabilityForm.linkMessage' });
          const linkPlaceholderMessage = intl.formatMessage({
            id: 'EditListingAvailabilityForm.linkPlaceholderMessage',
          });

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              {errorMessage}
              <div className={css.calendarWrapper}>
                <ManageAvailabilityCalendar
                  availability={availability}
                  availabilityPlan={availabilityPlan}
                  listingId={listingId}
                  currentListing={currentListing}
                  onShowCusPriceBtnClick={this.onShowCusPriceBtnClick}
                  intl={intl}
                />
              </div>

              <FieldTextInput
                id="iCalLink"
                name="iCalLink"
                className={css.title}
                type="text"
                label={linkMessage}
                placeholder={linkPlaceholderMessage}
              />

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </Form>
          );
        }}
      />
      <CusPriceForm
        showPriceDialog={this.state.showPriceDialog}
        closeDialogHandler={this.onCloseCusPriceBtnClick}
        date={this.state.cusPriceDate}
        // initialCustomPrice={initialCustomPrice}
        currentCusPrice={currentCusPrice}
        listingId={listingId}
        currentListing={currentListing}
        onSubmit={(values) => {
          // e.preventDefault();
          // e.stopPropagation();
          console.log('values');
          console.log(values);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
      />
      </div>
    );
  }
}

EditListingAvailabilityFormComponent.defaultProps = {
  updateError: null,
};

EditListingAvailabilityFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  availability: object.isRequired,
  availabilityPlan: propTypes.availabilityPlan.isRequired,
};

export default compose(injectIntl)(EditListingAvailabilityFormComponent);
