import { storableError } from '../../util/errors';
import moment from 'moment';

import { types as sdkTypes } from '../../util/sdkLoader';
import Decimal from 'decimal.js';
import { LINE_ITEM_SUM_CUSTOM_PRICE } from '../../util/types';
const { Money } = sdkTypes;

// ================ Pricing rates =============== //

export const PRICING_RATES = [
  'usual_rate',
  'weekend_rate',
  'event_rate',
  'custom_rate'];

// ================ Action types ================ //

export const SHOW_CUS_PRICE_DIALOG = 'app/CusPriceDialog/SHOW_CUS_PRICE_DIALOG';
export const HIDE_CUS_PRICE_DIALOG = 'app/CusPriceDialog/HIDE_CUS_PRICE_DIALOG';

export const UPDATE_CUS_PRICE_REQUEST = 'app/CusPriceDialog/UPDATE_CUS_PRICE_REQUEST';
export const UPDATE_CUS_PRICE_SUCCESS = 'app/CusPriceDialog/UPDATE_CUS_PRICE_SUCCESS';
export const UPDATE_CUS_PRICE_ERROR = 'app/CusPriceDialog/UPDATE_CUS_PRICE_ERROR';


// ================ Reducer ================ //

const initialState = {
  showPriceDialog: false,
  cusPriceDate: null,

  updateCusPriceRequest: false,
  updateCusPriceSuccess: false,
  updateCusPriceError: false,
};


export default function reducer(state = initialState, action = {}) {
  // console.log('action');
  // console.log(action);
  const { type, payload } = action;
  switch (type) {
    case SHOW_CUS_PRICE_DIALOG:
      return {
        ...state,
        showPriceDialog: true,
        cusPriceDate: payload.date,
        // priceCustom: payload.priceCusto,

        updateCusPriceRequest: false,
        updateCusPriceSuccess: false,
        updateCusPriceError: false,
      };
    case HIDE_CUS_PRICE_DIALOG:
      return {
        ...state,
        showPriceDialog: false,
        cusPriceDate: null,

        updateCusPriceRequest: false,
        updateCusPriceSuccess: false,
        updateCusPriceError: false,
      };
    case UPDATE_CUS_PRICE_REQUEST:
      return {
        ...state,
        updateCusPriceRequest: true,
        updateCusPriceSuccess: false,
        updateCusPriceError: false,
      };
    case UPDATE_CUS_PRICE_SUCCESS:
      return {
        ...state,
        updateCusPriceRequest: false,
        updateCusPriceSuccess: true,
        updateCusPriceError: false,
      };
    case UPDATE_CUS_PRICE_ERROR:
      return {
        ...state,
        updateCusPriceRequest: false,
        updateCusPriceSuccess: false,
        updateCusPriceError: true,
      };
    default:
      return state;
  }
};

// ================ Action creators ================ //

export const showPriceDialogAC = date => ({
  type: SHOW_CUS_PRICE_DIALOG,
  payload: date,
});

export const hidePriceDialogAC = () => ({
  type: HIDE_CUS_PRICE_DIALOG,
  // payload: date,
});

export const updateCusPriceStartedAC = () => ({
  type: UPDATE_CUS_PRICE_REQUEST,
  // payload: date,
});

export const updateCusPriceSucceedAC = () => ({
  type: UPDATE_CUS_PRICE_SUCCESS,
  // payload: date,
});

export const updateCusPriceFailedAC = (e) => ({
  type: UPDATE_CUS_PRICE_ERROR,
  payload: e,
});

// ================ Helpers ============== //

export function findCusPriceByDate(date, customPrices) {
  const dateStr = moment(date).format("YYYY-MM-DD");
  let cusPrices = (customPrices || []);
  return cusPrices.filter(el => el.date === dateStr )[0];
}

export function isWeekend(date) {
  const convertedDate = new Date(date);
  const weekend = [0, 4, 5, 6]; // weekend index
  const currentDate = convertedDate.getDay();
  const isWeekend = weekend.indexOf(currentDate);

  return (isWeekend !== -1 ? true : false);
}

export function findEffectivePriceByDate(date, currentListing) {
  let effectivePrice = currentListing && currentListing.attributes.price;
  let cusPrice = findCusPriceByDate(date, currentListing.attributes.publicData.customPrices);

  // use weekend price for all Fridays and Saturdays
  if (!cusPrice && isWeekend(date)) {
    cusPrice = buildCusPriceRecord(date, 'weekend_rate');
  }

  if (cusPrice && cusPrice.priceType !== 'custom_rate') {
    let pricePlan = currentListing.attributes.publicData['price_daily_' + cusPrice.priceType + ''];
    if (pricePlan) {
      effectivePrice = new Money(pricePlan.amount, pricePlan.currency);
    }
  } else if (cusPrice && cusPrice.priceType === 'custom_rate' && cusPrice.customValue) {
    effectivePrice = new Money(cusPrice.customValue.amount, cusPrice.customValue.currency);
  }

  return {
    customPrice: cusPrice,
    effectivePrice: effectivePrice
  }
}

export function calculateEffectivePricePeriod(startDate, daysCount, listing, itemsCount) {
  let periodPrices = [];
  let calculatedPriceAmount = 0;

  for (let i = 0; i < daysCount; i++) {
    let currDate = moment(startDate).add(i, 'days');
    let currPrice = findEffectivePriceByDate(currDate, listing);

    calculatedPriceAmount += (currPrice.effectivePrice.amount * itemsCount);
    periodPrices[i] = {
      date: currDate,
      itemsCount: itemsCount,
      customPrice: currPrice.cusPrice,
      effectivePrice: currPrice.effectivePrice
    }
  }

  return {
    effectiveTotal: new Money(calculatedPriceAmount, listing.attributes.price.currency),
    periodPrices: periodPrices
  }
}

export function buildLineItemsForPeriodPrices(periodPrices, listingCategory) {
  // let lineItems = [];

  let totalAmount = 0;
  let currency = null;
  let quantity = 1;

  periodPrices.forEach(rec => {
    if (!currency) {
      currency = rec.effectivePrice.currency;
    }
    totalAmount = totalAmount + rec.effectivePrice.amount;
    if (rec.itemsCount && listingCategory !== 'accommodations') {
      quantity = Number.parseInt(rec.itemsCount);
    }

    // lineItems[lineItems.length] = {
    //   code: 'line-item/date-custom-price-' + moment(rec.date).format('YYYY-MM-DD') + '',
    //   includeFor: ['customer', 'provider'],
    //   unitPrice: rec.effectivePrice,
    //   quantity: new Decimal(rec.itemsCount),
    //   lineTotal: rec.effectivePrice,
    //   reversal: false,
    // }
  });

  if (!(quantity && quantity > 0)) {
    quantity = 1;
  }

  // return lineItems;
  let sumLineItem = {
    code: LINE_ITEM_SUM_CUSTOM_PRICE,
    includeFor: ['customer', 'provider'],
    unitPrice: new Money(totalAmount, currency),
    quantity: new Decimal(quantity),
    lineTotal: new Money(totalAmount * quantity, currency),
    reversal: false,
  };

  return [sumLineItem];
}

// Clear old data to avoid publicData infinite bloating
function clearOldCustomPrices(customPrices) {
  return customPrices.filter(el => { return (moment(el.date) > moment().subtract(1, 'days')) } );
}

export function buildCusPriceRecord(date, priceType, customValue) {
  const dateStr = moment(date).format("YYYY-MM-DD");
  return {
    date: dateStr,
    priceType: priceType,
    customValue: customValue && {
      amount: customValue.amount,
      currency: customValue.currency
    }
  };

}

function buildUpdatedCusPrices(publicData, newCusPrice) {
  // clear old custom prices from publicData to avoid storing the outdated info
  let newCusPrices = clearOldCustomPrices([].concat(publicData.customPrices || []));
  let existingCusPrice = findCusPriceByDate(newCusPrice.date, newCusPrices);

  if (existingCusPrice) {
    Object.assign(existingCusPrice, newCusPrice);
  } else {
    newCusPrices[newCusPrices.length] = newCusPrice;
  }

  // Note: side-effect functions are evil, but...
  publicData.customPrices = newCusPrices;

  return newCusPrices;
}

// ================ Thunk ================ //

export function showPriceDialog(date) {
  return (dispatch, getState, sdk) => {
    return dispatch(showPriceDialogAC(date));
  };
}

export function hidePriceDialog() {
  return (dispatch, getState, sdk) => {
    return dispatch(hidePriceDialogAC());
  };
}

export function startUpdatingCusPrice(listingId, publicData, newCusPrice) {
  return (dispatch, getState, sdk) => {
    // console.log('listingId');
    // console.log(listingId);
    dispatch(updateCusPriceStartedAC());

    return sdk.ownListings
      .update({
        id: listingId,
        publicData: {
          customPrices: buildUpdatedCusPrices(publicData, newCusPrice)
        }
      })
      .then(() => {
        return dispatch(updateCusPriceSucceedAC());
      })
      .catch(e => {
        // log.error(e, 'update-listing-failed');
        return dispatch(updateCusPriceFailedAC(storableError(e)));
      });
  };
}
