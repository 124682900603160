import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: March 1, 2020</p>

      <p>
        Welcome to the Blue Mountain Accommodations Website (www. BLUEMOUNTAINACCOMMODATIONS.COM) 
        (BM Accommodations Inc.).
      </p>
      <p>
        Please read this Privacy Policy carefully. This policy, as modified or amended from time to time, 
        are a binding contract between BM Accommodations Inc. (“we” and/or “us”, etc) and you ("you”). 
      </p>
      <p>
        You may only use the website operated by BM Accommodations Inc. if you first accept this 
        Privacy Policy and the related Terms and Conditions of Use. 
      </p>

      <h2>
        WHAT THIS PRIVACY POLICY COVERS
      </h2>
      <p>
        This policy covers how BM Accommodations Inc. treats personal information that is collected 
        or received through the services we provide. Personal information is information about 
        you that is personally identifiable like your name, address, email address, or phone number, 
        and any information that is not otherwise publicly available.
      </p>
      <p>
        This policy does not apply to the practices of companies that we do not own or control, 
        or to people that we do not employ or manage. Companies such as our Internet access and 
        website and email hosting company, Facebook, and others have their own privacy statements 
        which can be viewed on their respective websites.
      </p>

      <h2>
        INFORMATION COLLECTION AND USE
      </h2>
      <p>
        BM Accommodations Inc. collects personal information when you contract with us to use or 
        purchase our services. When you contract with us for a particular service, we will ask 
        you to provide us with your name, email address, and a contact phone number. 
      </p>
      <p>
        Blue Mountain Accommodations website automatically receives and records information on our 
        server logs from your browser, including your IP address, location, type of browser and 
        device used, and the page you requested. The information from the server logs is used for 
        general website administration and optimization. If you submit content to one of our 
        inherently public features, we may retain the content as long as necessary to offer and 
        improve the services.
      </p>
      <p>
        BM Accommodations Inc. uses the information that is collected for the following general 
        purposes: to provide updates to you regarding products and services, to fulfill your 
        requests for products and services, improve products and services, contact you, conduct 
        research, to limit use to certain locations for certain members, and to optimize and 
        improve the website; to develop, operate, improve, deliver, maintain, and protect our 
        products and services; to send you communications, including by email; to personalize 
        the services by, among other things, suggesting friends or profile information, or 
        customizing the content we show you, including ads; enhance the safety and security of 
        our products and services; verify your identity and prevent fraud or other unauthorized 
        or illegal activity. We may also store some information locally on your device. 
        For example, we may store information as local cache so that you can open the app 
        and view content faster.
      </p>
      <p>
        We may let other companies use cookies, web beacons, and similar tracking technologies 
        on our services. These companies may collect information about how you use our services 
        and other websites and online services over time and across different services. 
        This information may be used to, among other things, analyze and track data, determine 
        the popularity of certain content, and better understand your online activity. 
        Additionally, some companies may use information collected on our services to deliver 
        targeted advertisements on behalf of us or other companies, including on third-party 
        websites and apps.
      </p>
      <p>
        If your location is collected, it is used only to ensure that use of the Application is 
        localized geographically, and is not stored for any other purpose.
      </p>

      <h2>
        EMAILS
      </h2>
      <p>
        We will occasionally send e-mails to you. This is required for certain uses of the Website such 
        as user registration and use of the Website.  We may also send information to you regarding 
        your account activity and purchases as well as updates about our products and promotional offers. 
        You may elect to opt-out of receiving promotional emails at any time after registering 
        by e-mailing <a href='mailto:info@BlueMountainAccommodations.com' rel="noopener noreferrer"
        target='_blank' title='info@BlueMountainAccommodations.com'>info@BlueMountainAccommodations.com</a> and 
        including your name, address, email address, and what emails you do not want to receive.
      </p>

      <h2>CHILDREN</h2>
      <p>
        BM Accommodations Inc. does not design any part of its website to attract anyone under age 18. 
        BM Accommodations Inc. will not knowingly contact children under age 18 without a parent's 
        permission. BM Accommodations Inc. does not knowingly ask a child under age 18 for any personal information.
      </p>

      <h2>
        INFORMATION SHARING AND DISCLOSURE
      </h2>
      <p>
        BM Accommodations Inc. does not rent, sell or share personal information about you with other 
        people or companies without your permission except under the following circumstances:
      </p>

      <ul>
        <li>
          We respond to subpoenas, court orders, or legal process, or to establish or exercise our 
          legal rights or defend against legal claims.
        </li>
        <li>
          We share information with law enforcement agencies in order to investigate, prevent, or 
          take action regarding illegal activities, suspected fraud, suspected copyright infringement, 
          situations involving potential threats to the physical safety of any person, or as 
          otherwise required by law.
        </li>
      </ul>
      <p>
        In some cases, by using the services, you are agreeing that we may share your personal 
        information with another party. For example, if you wish to communicate with another 
        user of the website in order to consider a transaction between you.
      </p>

      <h2>
        CONFIDENTIALITY AND SECURITY
      </h2>
      <p>
        We limit access to personal information about you to only those we believe reasonably need 
        to come into contact with that information to provide our products or services to you. 
        We have physical, electronic, and procedural safeguards to protect personal information about you. 
      </p>
      <p>
        Other users of the Application who see the content you provide can always save it using any 
        number of techniques: screenshots, in-app functionality, or any other image-capture technology. 
      </p>

      <h2>
        COOKIES
      </h2>
      <p>
        We may use technologies such as cookies to collect information about the pages viewed by you, 
        the links clicked by you and other actions taken by you on our website and services. 
        Additionally, we collect certain standard information that your browser sends to 
        every website visited by you, such as your IP address, browser type and language, 
        access times and referring website addresses. Also device used.
      </p>

      <h2>
        CHANGES TO THIS PRIVACY POLICY
      </h2>
      <p>
        This policy will be changed from time to time to adapt to the changing needs of the society 
        and the law. We will notify you about significant changes in the way we treat personal 
        information by amending this Privacy Policy on the website.
      </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
