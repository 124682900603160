import React, { Component } from 'react';
// import { bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import {
  // intlShape,
  injectIntl,
  FormattedMessage
} from '../../util/reactIntl';
// import classNames from 'classnames';
// import { propTypes } from '../../util/types';
import { Form, Button, Modal, FieldCurrencyInput, FieldRadioButton } from '../../components';
import moment from 'moment';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';

import * as model from './CusPriceDialog.duck'

import css from './CusPriceForm.css';

const { Money } = sdkTypes;

class CusPriceFormComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {}

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {

    const newPrice = model.buildCusPriceRecord(
      this.props.date,
      values.priceRateType,
      values.priceCustom
    );

    if (values.priceCustom || values.priceRateType && values.priceRateType !== 'custom_rate') {
      this.setState({
        cusPriceError: null
      });

      this.props.dispatch(model.startUpdatingCusPrice(
        this.props.listingId,
        this.props.currentListing.attributes.publicData,
        newPrice)
      ).then(() => {
        if (!this.props.updateCusPriceError) {
          this.props.closeDialogHandler();
        }
        
      });

    } else {
      this.setState({
        cusPriceError: true
      });
    }
  }

  render() {

    const props = { ...this.props, onSubmit: this.handleSubmit };

    const currentCusPrice = props.currentCusPrice;
    let initialCustomPrice = null;
    if (currentCusPrice && currentCusPrice.customValue) {
      initialCustomPrice = new Money(currentCusPrice.customValue.amount, currentCusPrice.customValue.currency)
    }
    
    return (
      this.props.date && <FinalForm
        {...props}
        
        initialValues={
          {
            priceCustom: initialCustomPrice,
            priceRateType: (currentCusPrice && currentCusPrice.priceType) || 'usual_rate',
          }
        }
        render={fieldRenderProps => {
          const {
            // className,
            // rootClassName,
            currentListing,
            showPriceDialog,
            date,
            handleSubmit,
            closeDialogHandler,
            updateCusPriceError,
            onManageDisableScrolling,
          } = fieldRenderProps;

          const errorMessage = (updateCusPriceError || this.state.cusPriceError) ? (
            <p className={css.error}>
              {this.state.cusPriceError && <FormattedMessage id="EditListingPricingForm.priceRequired" />}
              {updateCusPriceError && <FormattedMessage id="EditListingAvailabilityForm.updateFailed" />}
            </p>
          ) : null;

          return (

            <Modal
              id="EditListingCusPrice.priceFormModal"
              isOpen={showPriceDialog}
              onClose={closeDialogHandler}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <span className={css.modalHeader}>
                Choose rate option for {moment(date).format('YYYY-MM-DD')}:
              </span>
              
              <Form
                // className={classes}
                // onSubmit={this.handleSubmit}
                onSubmit={handleSubmit}
              >

                <div className={css.radioButtonRow}>
                  <FieldRadioButton
                    id={`priceRate-option-id1`}
                    name="priceRateType"
                    label="Usual rate"
                    value="usual_rate"
                  />
                </div>
                
                <div className={css.radioButtonRow}>
                  <FieldRadioButton
                    id={`priceRate-option-id2`}
                    name="priceRateType"
                    label="Weekend rate"
                    value="weekend_rate"
                  />
                </div>
                
                <div className={css.radioButtonRow}>
                  <FieldRadioButton
                    id={`priceRate-option-id3`}
                    name="priceRateType"
                    label="Event rate"
                    value="event_rate"
                  />
                </div>
                
                <div className={css.radioButtonRow}>
                  <FieldRadioButton
                    id={`priceRate-option-id4`}
                    name="priceRateType"
                    label="Custom rate"
                    value="custom_rate"
                  />
                </div>
                

                <FieldCurrencyInput
                  id="priceCustom"
                  name="priceCustom"
                  label="Set rate:"
                  currencyConfig={config.currencyConfig}
                  value={initialCustomPrice}
                />

                {errorMessage}
                
                <Button
                  // className={css.submitButton}
                  type="submit"
                  // inProgress={submitInProgress}
                  // disabled={submitDisabled}
                  // ready={submitReady}
                >
                  <FormattedMessage id="CusPriceForm.saveBtn" />
                </Button>
              </Form>
                  
            </Modal>
          );
        }}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    updateCusPriceError: state.CusPriceDialog.updateCusPriceError,
  };
}

const mapDispatchToProps = dispatch => (
  {
    dispatch: dispatch,
    // updateCusPrice: (listingId, publicData, newCusPrice) => dispatch(model.startUpdatingCusPrice(listingId, publicData, newCusPrice)),
  }
);

export const CusPriceForm = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(CusPriceFormComponent);

export default CusPriceForm;
