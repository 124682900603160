import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
// import { ValidationError } from '../../components';
import css from './FieldQuantityInput.css';



class FieldQuantityInputComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonMinusActive: this.props.input > 1,
    };
  }

  handleMinus(e) {
    e.preventDefault();
    e.stopPropagation();
    let currentValue = +Number.parseInt(this.props.input.value);

    if (currentValue > 1) {
      currentValue = currentValue - 1;
      this.props.input.onChange(currentValue);
    }

    if (currentValue <= 1) {
      this.setState({
        buttonMinusActive: false
      })
    }
  }

  handlePlus(e) {
    e.preventDefault();
    e.stopPropagation();
    let currentValue = +Number.parseInt(this.props.input.value);

    if (currentValue < this.props.maxValue) {
      currentValue = currentValue + 1;
      this.props.input.onChange(currentValue);
    }

    if (currentValue >= 1) {
      this.setState({
        buttonMinusActive: true
      })
    }
  }

  // shouldComponentUpdate() {

  // }

  render() {
    const {
      rootClassName,
      className,
      inputRootClass,
      id,
      label,
      input,
      meta,
      onUnmount,
      customErrorText,
      maxValue,
      // selectedItemsCount,
      ...rest
    } = this.props;

    const buttonMinusClasses = this.state.buttonMinusActive ? css.buttonMinus : css.buttonMinusDisabled;
    const { valid, invalid, touched, error } = meta;
    const hasError = !!customErrorText || !!(touched && invalid && error);

    const inputClasses =
      inputRootClass ||
      classNames(css.input, {
        [css.inputSuccess]: valid,
        [css.inputError]: hasError,
      });
    const inputProps = {
      className: inputClasses,
      placeholder: "0",
      type: input.type,
      id,
      ...input,
      ...rest
    };

    const classes = classNames(rootClassName || css.fieldRoot, className);
    return (
      <div className={classes}>
        {/* {selectedItemsCount} */}
        {label ?
            <label htmlFor={id} className={css.label}>
                <FormattedMessage id={label} />
            </label>
        : null}
        <div className={css.quantityInputWrapper}>
          <button className={buttonMinusClasses} onClick={(e) => {this.handleMinus(e)}}>-</button>
          {<input {...inputProps} />}
          <button className={css.buttonPlus} onClick={(e) => {this.handlePlus(e)}}>+</button>
        </div>
      </div>
    );
  }
}

FieldQuantityInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
};

FieldQuantityInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,

  onUnmount: func,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Either 'textarea' or something that is passed to the input element
  // TO DO
  // type: string.isRequired,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
  }).isRequired,
  meta: object.isRequired,
};

class FieldQuantityInput extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldQuantityInputComponent} {...this.props} />;
  }
}

export default FieldQuantityInput;
