import React, { Component } from 'react';
import { string, bool, arrayOf } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import moment from 'moment';
import { required, bookingDatesRequired, composeValidators } from '../../util/validators';
import { START_DATE, END_DATE } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Form, PrimaryButton, FieldDateRangeInput } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldQuantityInput from '../../components/FieldQuantityInput/FieldQuantityInput'

import css from './BookingDatesForm.css';

const identity = v => v;

export class BookingDatesFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      selectedDateStart: null,
      selectedDateEnd: null,
      selectedItemsCount: 1,
      maxSeatsCount: 0,
      errorNotEnoughSeats: false,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);

    this.updateSelectedDateStart = this.updateSelectedDateStart.bind(this);
    this.updateSelectedDateEnd = this.updateSelectedDateEnd.bind(this);
    this.updateSelectedItemsCount = this.updateSelectedItemsCount.bind(this);
    this.calcMaxItemsCount = this.calcMaxItemsCount.bind(this);
    this.spyChangeHandler = this.spyChangeHandler.bind(this);
  }

  updateSelectedDateStart(date) {
    this.setState({ selectedDateStart: date });
  }
  updateSelectedDateEnd(date) {
    this.setState({ selectedDateEnd: date });
  }
  updateSelectedItemsCount(count) {
    this.setState({ selectedItemsCount: count });
  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  handleFormSubmit(e) {
    const itemsQuantity = e.items_quantity || 1;
    const { startDate, endDate } = e.bookingDates || {};

    const customEndDate = new Date(startDate);
    customEndDate.setDate(startDate.getDate() + 1);
    endDate === null ? localStorage.setItem('oneDay', 'true') : localStorage.setItem('oneDay', 'false');

    if (itemsQuantity > 1 &&
          itemsQuantity > this.calcMaxItemsCount(startDate, endDate, customEndDate)) {

      this.setState({ errorNotEnoughSeats: true });
      return false;
    }

    if (!startDate) {
      e.preventDefault();
      this.setState({ focusedInput: START_DATE });
    } else if (!customEndDate && !endDate) {
      e.preventDefault();
      this.setState({ focusedInput: END_DATE });
    } else {
      this.props.onSubmit(e);
    }
  }

  calcMaxItemsCount(startDate, endDate, customEndDate) {

    if (!startDate || (!endDate && !customEndDate) || !this.props.timeSlots) {
      return 1;
    }

    endDate = endDate !== null ? endDate : customEndDate;

    const actualSlots = this.props.timeSlots.filter(sl => {
      return moment(sl.attributes.start).utc().isBetween(
        moment(startDate).utc(),
        moment(endDate).utc(),
        'day',
        "[)");
    });

    const minSeatsSlot = actualSlots.sort((a1, a2) => {
      return a1.attributes.seats - a2.attributes.seats;
    })[0];

    const minSeatsCount = minSeatsSlot && minSeatsSlot.attributes.seats;
    localStorage.setItem('slotsFree', minSeatsCount ? 'true' : 'false');
    const maxItemsCount = minSeatsCount || 1;

    return maxItemsCount;
  }

  spyChangeHandler(spyValues) {
    const values = spyValues.values;
    let customEndDate = new Date();
    if (values.bookingDates && values.bookingDates.startDate) {
      customEndDate = new Date(values.bookingDates.startDate);
      customEndDate.setDate(values.bookingDates.startDate.getDate() + 1);
    }

    if (values.bookingDates && values.bookingDates.startDate) {
      const maxItemsCount = this.calcMaxItemsCount(
        // slots,
        values.bookingDates.startDate,
        values.bookingDates.endDate,
        customEndDate
      );

      if (values.items_quantity && values.items_quantity > maxItemsCount) {
        this.setState({
          selectedItemsCount: maxItemsCount
        });
      }

      this.setState({
        maxSeatsCount: maxItemsCount
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      price: unitPrice,
      timeSlots,
      ...rest } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        timeSlots={timeSlots}
        onSubmit={this.handleFormSubmit}
        initialValues={{
          items_quantity: this.state.selectedItemsCount,
        }}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            formId,
            handleSubmit,
            intl,
            isOwnListing,
            submitButtonWrapperClassName,
            unitPrice,
            unitType,
            values,
            timeSlots,
            fetchTimeSlotsError,
            listing,
          } = fieldRenderProps;

          const listingCategory =
            listing &&
            listing.attributes &&
            listing.attributes.publicData &&
            listing.attributes.publicData.category;

          let showQuantityFields = false;
          let labelQuantityField = '';

          if (listingCategory) {
            switch (listingCategory) {
              case 'equipment':
                showQuantityFields = true;
                labelQuantityField = 'BookingDatesForm.quantityEquipment';
                break;
              case 'adventures':
                showQuantityFields = true;
                labelQuantityField = 'BookingDatesForm.quantityAdventures';
                break;
              case 'accommodations':
                showQuantityFields = true;
                labelQuantityField = 'BookingDatesForm.quantityAdventures';
                break;

              default:
                break;
            }
          }

          const { startDate, endDate } = values && values.bookingDates ? values.bookingDates : {};

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({ id: 'BookingDatesForm.bookingEndTitle' });
          const requiredMessage = intl.formatMessage({ id: 'BookingDatesForm.requiredDate' });
          const startDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidStartDate',
          });
          const endDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidEndDate',
          });
          const timeSlotsError = fetchTimeSlotsError ? (
            <p className={css.timeSlotsError}>
              <FormattedMessage id="BookingDatesForm.timeSlotsError" />
            </p>
          ) : null;

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData = startDate
            ? {
                unitType,
                unitPrice,
                startDate,
                endDate,
                listing,

                // NOTE: If unitType is `line-item/units`, a new picker
                // for the quantity should be added to the form.
                quantity: values.items_quantity || 1,
              }
            : null;
          const bookingInfo =
            bookingData &&
            !this.state.errorNotEnoughSeats &&
            (localStorage.slotsFree === 'true' && bookingData) ? (
              <div className={css.priceBreakdownContainer}>
                <h3 className={css.priceBreakdownTitle}>
                  <FormattedMessage id="BookingDatesForm.priceBreakdownTitle" />
                </h3>
                <EstimatedBreakdownMaybe bookingData={bookingData} />
              </div>
            ) : null;

          const dateFormatOptions = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          };

          const now = moment();
          const today = now.startOf('day').toDate();
          const tomorrow = now
            .startOf('day')
            .add(1, 'days')
            .toDate();
          const startDatePlaceholderText =
            startDatePlaceholder || intl.formatDate(today, dateFormatOptions);
          const endDatePlaceholderText =
            endDatePlaceholder || intl.formatDate(tomorrow, dateFormatOptions);
          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );
          const category = listing.attributes.publicData.category;

          return (
            <Form onSubmit={handleSubmit} className={classes}>
              {timeSlotsError}
              <FormSpy onChange={this.spyChangeHandler} />
              <FieldDateRangeInput
                className={css.bookingDates}
                name="bookingDates"
                unitType={unitType}
                startDateId={`${formId}.bookingStartDate`}
                startDateLabel={bookingStartLabel}
                startDatePlaceholderText={startDatePlaceholderText}
                endDateId={`${formId}.bookingEndDate`}
                endDateLabel={bookingEndLabel}
                endDatePlaceholderText={endDatePlaceholderText}
                focusedInput={this.state.focusedInput}
                onFocusedInputChange={this.onFocusedInputChange}
                format={identity}
                timeSlots={timeSlots}
                useMobileMargins
                validate={composeValidators(
                  required(requiredMessage),
                  bookingDatesRequired(startDateErrorMessage, endDateErrorMessage, category)
                )}
              />
              {showQuantityFields && (
                <FieldQuantityInput
                  id={`items_quantity`}
                  type="number"
                  name={`items_quantity`}
                  label={labelQuantityField}
                  maxValue={this.state.maxSeatsCount}
                  // maxValue={999}
                  readOnly={true}
                />
              )}
              {typeof window !== 'undefined' && localStorage.slotsFree === 'true' && bookingData ? (
                category === 'equipment' ? (
                  <p className={css.quantityNotification}>
                    <FormattedMessage
                      id="BookingDatesForm.quantityNotificationEquipment"
                      values={{ quantity: this.state.maxSeatsCount }}
                    />
                  </p>
                ) : (
                  <p className={css.quantityNotification}>
                    <FormattedMessage
                      id="BookingDatesForm.quantityNotificationAdventures"
                      values={{ quantity: this.state.maxSeatsCount }}
                    />
                  </p>
                )
              ) : null}
              {bookingInfo}
              {this.state.errorNotEnoughSeats ||
              (typeof window !== 'undefined' &&
                localStorage.slotsFree === 'false' &&
                bookingData &&
                category === 'equipment') ? (
                <span className={css.error}>
                  <FormattedMessage id="BookingDatesForm.errorNotEnoughSeats" />
                </span>
              ) : null}
              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingDatesForm.ownListing'
                      : 'BookingDatesForm.youWontBeChargedInfo'
                  }
                />
              </p>
              <div className={submitButtonClasses}>
                <PrimaryButton
                  type="submit"
                  disabled={typeof window !== 'undefined' && localStorage.slotsFree === 'false'}
                >
                  <FormattedMessage id="BookingDatesForm.requestToBook" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingDatesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: null,
};

BookingDatesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  timeSlots: arrayOf(propTypes.timeSlot),

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingDatesForm = compose(injectIntl)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;
