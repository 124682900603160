import React, { Component } from 'react';
import { withRouter, Redirect} from 'react-router-dom';
import Cookies from 'js-cookie';
import { injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { loginSuccess } from '../../ducks/Auth.duck';
import { NamedRedirect } from '../../components';

export class FacebookResultPageComponent extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const params = require('query-string');
    const allParams = params.parse(this.props.location.search);
    const cookieFlex = {
      access_token: allParams.access_token,
      expires_in: allParams.expires_in,
      refresh_token: allParams.refresh_token,
      scope: allParams.scope,
      token_type: allParams.token_type
    };

    Cookies.set(`st-${process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID}-token`, JSON.stringify(cookieFlex));
    this.props.loginSuccess();
    this.props.fetchCurrentUser();

  }

  render() {
    this.props.location.hash = '';
    this.props.history.location.hash = '';
    const params = require('query-string');
    const allParams = params.parse(this.props.location.search);

    if (allParams.error === 'true') {
      return (
        <Redirect to={`/login?error=${allParams.text}`} />
      )
    } else {
      return (
        this.props.isAuthenticated && <NamedRedirect name="LoginPage" />
      )
    }
  }
}

FacebookResultPageComponent.defaultProps = {

};

FacebookResultPageComponent.propTypes = {

};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  return {
    isAuthenticated
  }
};

const mapDispatchToProps = dispatch => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
  loginSuccess: () => dispatch(loginSuccess())
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const FacebookResultPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(FacebookResultPageComponent);

export default FacebookResultPage;
