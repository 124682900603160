import { storableError } from '../../util/errors';
import axios from 'axios';

// ================ Action types ================ //

export const CHANGE_PASSWORD_REQUEST = 'app/PasswordChangePage/CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'app/PasswordChangePage/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'app/PasswordChangePage/CHANGE_PASSWORD_ERROR';
export const CHANGE_PASSWORD_ALT_REQUEST = 'app/PasswordChangePage/CHANGE_PASSWORD_ALT_REQUEST';
export const CHANGE_PASSWORD_ALT_SUCCESS = 'app/PasswordChangePage/CHANGE_PASSWORD_ALT_SUCCESS';
export const CHANGE_PASSWORD_ALT_ERROR = 'app/PasswordChangePage/CHANGE_PASSWORD_ALT_ERROR';

export const CHANGE_PASSWORD_CLEAR = 'app/PasswordChangePage/CHANGE_PASSWORD_CLEAR';
export const CHANGE_PASSWORD_ALT_CLEAR = 'app/PasswordChangePage/CHANGE_PASSWORD_ALT_CLEAR';

// ================ Reducer ================ //

const initialState = {
  changePasswordError: null,
  changePasswordInProgress: false,
  passwordChanged: false,
};

const API_URL = process.env.REACT_APP_API_URL;
const headers = { 'headers': {'Accept': 'application/json'}};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePasswordInProgress: true,
        changePasswordError: null,
        passwordChanged: false,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, changePasswordInProgress: false, passwordChanged: true };
    case CHANGE_PASSWORD_ERROR:
      return { ...state, changePasswordInProgress: false, changePasswordError: payload };

    case CHANGE_PASSWORD_ALT_REQUEST:
      return {
        ...state,
        changePasswordInProgress: true,
        changePasswordError: null,
        passwordChanged: false,
      };
    case CHANGE_PASSWORD_ALT_SUCCESS:
      return { ...state, changePasswordInProgress: false, passwordChanged: true };
    case CHANGE_PASSWORD_ALT_ERROR:
      return { ...state, changePasswordInProgress: false, changePasswordError: payload };

    case CHANGE_PASSWORD_CLEAR:
      return { ...initialState };
    case CHANGE_PASSWORD_ALT_CLEAR:
      return { ...initialState };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const changePasswordRequest = () => ({ type: CHANGE_PASSWORD_REQUEST });
export const changePasswordSuccess = () => ({ type: CHANGE_PASSWORD_SUCCESS });
export const changePasswordError = error => ({
  type: CHANGE_PASSWORD_ERROR,
  payload: error,
  error: true,
});

export const changePasswordAltRequest = () => ({ type: CHANGE_PASSWORD_ALT_REQUEST });
export const changePasswordAltSuccess = () => ({ type: CHANGE_PASSWORD_ALT_SUCCESS });
export const changePasswordAltError = error => ({
  type: CHANGE_PASSWORD_ALT_ERROR,
  payload: error,
  error: true,
});

export const changePasswordClear = () => ({ type: CHANGE_PASSWORD_CLEAR });

// ================ Thunks ================ //

export const changePassword = params => (dispatch, getState, sdk) => {
  dispatch(changePasswordRequest());
  const { newPassword, currentPassword } = params;

  return sdk.currentUser
    .changePassword({ newPassword, currentPassword })
    .then(() => dispatch(changePasswordSuccess()))
    .catch(e => {
      dispatch(changePasswordError(storableError(storableError(e))));
      // This is thrown so that form can be cleared
      // after a timeout on changePassword submit handler
      throw e;
    });
};

export const changePasswordAlt = (requestParams, cb) => (dispatch) => {
  dispatch(changePasswordAltRequest());

  const params = {
    email: requestParams.email,
    password: requestParams.currentPassword,
    new_password: requestParams.newPassword,
    token: requestParams.token
  };

  axios.post(`${API_URL}/api/v1/users/reset_password`, params, headers)
    .then(() => {
      cb();
      dispatch(changePasswordSuccess())
    })
    .catch(err => {
      dispatch(changePasswordError(err));
    });
};
