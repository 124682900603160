import React from 'react';
import { arrayOf, bool, func, shape, string, object } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, isPositiveInteger, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldSelect, FieldCheckboxGroup } from '../../components';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';
import config from '../../config';
import arrayMutators from 'final-form-arrays';

import * as lodash from 'lodash';
import { defaultAvailabilityPlan } from '../../containers/EditListingPage/EditListingPage.duck';
import css from './EditListingDescriptionForm.css';

const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = props => {
  let availabilityPlan = lodash.get(props.listing, 'attributes.availabilityPlan', null);

  const oldAvailItemsNumber = lodash.get(
    props.listing,
    'attributes.availabilityPlan.entries[0].seats',
    1
  );

  const newInitialValues = Object.assign(props.initialValues, {
    availItemsNumber: oldAvailItemsNumber,
  });

  const handleSubmitWithSeats = values => {
    // Update seats for the whole availability plan:
    let newAvailItemsNumber = 1;
    if (
      (values.category && values.category === 'equipment') ||
      (values.category && values.category === 'adventures') ||
      (values.category && values.category === 'accommodations')
    ) {
      newAvailItemsNumber = Number(values.availItemsNumber);
    }

    if (newAvailItemsNumber !== oldAvailItemsNumber) {
      if (!availabilityPlan) {
        availabilityPlan = {};
        Object.assign(availabilityPlan, defaultAvailabilityPlan);
      }

      availabilityPlan.entries.forEach(el => {
        el.seats = newAvailItemsNumber;
      });

      if (!values.attributes) {
        Object.assign(values, {
          attributes: {
            availabilityPlan,
          },
        });
      } else {
        Object.assign(values.attributes, {
          availabilityPlan: availabilityPlan,
        });
      }
    } else {
      // console.log('Nothing to do!');
    }

    props.onSubmit(values);
  };

  return (
    <FinalForm
      {...props}
      onSubmit={handleSubmitWithSeats}
      initialValues={newInitialValues}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const {
          categories,
          className,
          disabled,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          values,
          subcategories,
        } = fieldRenderProps;

        const { category } = values;

        const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
        const titlePlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.titlePlaceholder',
        });
        const titleRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.titleRequired',
        });
        const maxLengthMessage = intl.formatMessage(
          { id: 'EditListingDescriptionForm.maxLength' },
          {
            maxLength: TITLE_MAX_LENGTH,
          }
        );

        const descriptionMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.description',
        });
        const descriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.descriptionPlaceholder',
        });
        const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
        const descriptionRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.descriptionRequired',
        });

        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = updated && pristine;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        const subCategoryRequired = required(
          intl.formatMessage({
            id: 'EditListingDescriptionForm.subCategoryRequired',
          })
        );

        const subcategory = subcategories[category] ? (
          <FieldSelect
            className={css.subcategory}
            name={'subcategory'}
            id={'subcategory'}
            label={`Type of ${category}`}
            validate={subCategoryRequired}
          >
            <option disabled value="">
              {`Choose the type of your ${category}`}
            </option>
            {subcategories[category].map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
          </FieldSelect>
        ) : null;

        const amenitiesLabel = <FormattedMessage id="EditListingDescriptionForm.amenitiesLabel" />;

        const amenities =
          category && category === 'accommodations' ? (
            <FieldCheckboxGroup
              className={css.amenities}
              id={'amenities'}
              name={'amenities'}
              label={amenitiesLabel}
              options={config.custom.amenities}
              twoColumns={true}
            />
          ) : null;

        const availItemsMessageEquipment = intl.formatMessage({
          id: 'EditListingDescriptionForm.availItemsLabelEquipment',
        });

        const availItemsMessageAdventures = intl.formatMessage({
          id: 'EditListingDescriptionForm.availItemsLabelAdventures',
        });

        const availItemsRequiredMsg = intl.formatMessage({
          id: 'EditListingDescriptionForm.availItemsRequiredLabel',
        });

        const availItemsPostvMsg = intl.formatMessage({
          id: 'EditListingDescriptionForm.availItemsPositiveLabel',
        });

        const availItemsNumber =
          (category && category === 'equipment') ||
          (category && category === 'adventures') ||
          (category && category === 'accommodations') ? (
            <FieldTextInput
              id="availItemsNumber"
              name="availItemsNumber"
              // className={css.description}
              type="text"
              label={
                category === 'equipment' ? availItemsMessageEquipment : availItemsMessageAdventures
              }
              // value={oldAvailItemsNumber}
              // placeholder={descriptionPlaceholderMessage}
              validate={composeValidators(
                required(availItemsRequiredMsg),
                isPositiveInteger(availItemsPostvMsg)
              )}
            />
          ) : null;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}
            <FieldTextInput
              id="title"
              name="title"
              className={css.title}
              type="text"
              label={titleMessage}
              placeholder={titlePlaceholderMessage}
              maxLength={TITLE_MAX_LENGTH}
              validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
              autoFocus
            />

            <FieldTextInput
              id="description"
              name="description"
              className={css.description}
              type="textarea"
              label={descriptionMessage}
              placeholder={descriptionPlaceholderMessage}
              validate={composeValidators(required(descriptionRequiredMessage))}
            />

            <CustomCategorySelectFieldMaybe
              id="category"
              name="category"
              categories={categories}
              intl={intl}
            />

            {subcategory}

            {amenities}

            {availItemsNumber}

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  subcategories: object,
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
