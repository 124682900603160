import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_SUM_CUSTOM_PRICE, propTypes } from '../../util/types';

import css from './BookingBreakdown.css';

const LineItemMultiPriceMaybe = props => {
  const {
    transaction,
    // unitType,
    intl,
    listing,
  } = props;
  const translationKey = 'BookingBreakdown.simpleUnitQuantity';
  const translationKeyAccommodations = 'BookingBreakdown.simpleUnitQuantityAccommodations';

  const listingCategory = listing ? listing.attributes.publicData.category : null;

  // Find correct line-item for given unitType prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
  // These are defined in '../../util/types';
  const unitPurchases = transaction.attributes.lineItems.filter(
    // item => item.code.indexOf('line-item/date-custom-price') != -1 && !item.reversal
    item => item.code === LINE_ITEM_SUM_CUSTOM_PRICE && !item.reversal
  );

  let lineItemsRendered = [];

  unitPurchases.forEach(unitPurchase => {
    const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
    const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
    const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;

    if (quantity && total) {
      lineItemsRendered.push(
        <div className={css.lineItem} key={unitPurchase.code}>
          <span className={css.itemLabel}>
            {listingCategory !== 'accommodations' ? (
              <FormattedMessage id={translationKey} values={{ unitPrice, quantity }} />
            ) : (
              <FormattedMessage id={translationKeyAccommodations} values={{ unitPrice }} />
            )}
          </span>
          <span className={css.itemValue}>{total}</span>
        </div>
      );
    }
  });

  return lineItemsRendered;
};

LineItemMultiPriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemMultiPriceMaybe;
