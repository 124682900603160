import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ListingCard, PaginationLinks } from '../../components';
import css from './SearchResultsPanel.css';

export default class SearchResultsPanel extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: []
    };
  }

  componentDidUpdate(prevProps) {
    const { listings } = this.props;

    listings.length !== 0 && this.randomListings(listings);
  }

  randomListings(arr) {
    const data = arr.sort(function(){
      return Math.random() - 0.5;
    });

    if (this.state.data.length === 0 || (this.state.data.length !== 0 && arr.length !== this.state.data.length)) {
      this.setState({ data });
    }
  }

  componentWillUnmount() {
    this.setState({ data: [] })
  }

  render() {
    const { className, rootClassName, pagination, search, setActiveListing } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const paginationLinks =
      pagination && pagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName="SearchPage"
          pageSearchParams={search}
          pagination={pagination}
        />
      ) : null;

    // Panel width relative to the viewport
    const panelMediumWidth = 50;
    const panelLargeWidth = 62.5;
    const cardRenderSizes = [
      '(max-width: 767px) 100vw',
      `(max-width: 1023px) ${panelMediumWidth}vw`,
      `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
      `${panelLargeWidth / 3}vw`,
    ].join(', ');

    return (
      <div className={classes}>
        <div className={css.listingCards}>
          {this.state.data.map(l => (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes}
              setActiveListing={setActiveListing}
            />
          ))}
          {this.props.children}
        </div>
        {paginationLinks}
      </div>
    );
  }

}

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};
