import React from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  
} from '../../components';

import css from './AboutPage.css';


const AboutPage = () => {

  // prettier-ignore
  return (
    <StaticPage
      title="About Us | Blue Mountain Accommodations"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'At Blue Mountain Accommodations, we are building the most trusted rental community by bringing hosts and guests together to create unforgettable vacation experiences.',
        name: 'About page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1>Hello, We Are Blue Mountain Accommodations</h1>
          <br/>
          <h2 className={css.sectionTitle}>What Are We About?</h2>
          <p>At Blue Mountain Accommodations, our key mission is to bring together guests and hosts to create truly 
            exceptional Blue Mountain vacation experiences. We open doors to the finest homes, chalets, and condos 
            in Blue Mountain to our guests as well as help them find their perfect adventure to go on and get the best 
            deals on equipment. 
          </p>
          <p>We are truly committed to delivering a unique experience to our guests and hosts.</p>

          <h2 className={css.sectionTitle}>How Do We Do It?</h2>
          <p>
            Blue Mountain Accommodations is a rental community that is built on trust. We carefully select our hosts and 
            guests to ensure a hassle-free and pleasant experience for both parties. 
          </p>
          <p>
            Choosing is made easy within our platform – thanks to extensive detailed profiles, personal reviews and 
            confirmed verifications, our users are sure to find the perfect host or guest.
          </p>
          <p>
            To connect hosts and guests, we created a secure smart internal messaging system that allows them to send messages, 
            verify personal profiles, and transfer payments in a matter of clicks.
          </p>

          <h2 className={css.sectionTitle}>Why Choose Blue Mountain Accommodations?</h2>
          <p>
            If you are looking to have the best time of your life in Blue Mountains, be it a family vacation, romantic getaway, 
            business trip, bachelorette or bachelor party, or pretty much anything else that comes to your 
            mind – Blue Mountain Accommodations provides unmatched service. 
          </p>
          <p>
            If you are looking to rent your property, equipment or list your experience – Blue Mountain Accommodations is happy 
            to help. 
          </p>
          <p>
            We are here for you 24/7 – our customer support is always ready to answer any questions you might have during your 
            vacation/rental experience with us.
          </p>

        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default AboutPage;
