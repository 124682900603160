import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: March 1, 2020</p>

      <p>
        The following terms and conditions apply for use of the BLUEMOUNTAINACCOMMODATIONS.COM website (The "Site").
      </p>
      <p>
        The Site is operated by BM Accommodations Inc., a Saskatchewan corporation.
      </p>

      <h2>
        1. DEFINITIONS
      </h2>
      <p>
        In these terms and conditions the following expressions are used:
      </p>
      <p>
        "BlueMountainAccommodations", "we", "us" and "our" refers to the Site but can also refer to 
        BM Accommodations Inc.
      </p>
      <p>
        "you" refers to the user of the Site, in any capacity.
      </p>
      <p>
        "Property" means any accommodation shown on the Site.
      </p>
      <p>
        "Owner" means the owner of a Property shown on the Site.
      </p>
      <p>
        "Agent" refers to an individual or organisation acting as a rental booking agent, real estate agent 
        or property manager with explicit authority of the Owner.
      </p>
      <p>
        "Renter" refers to any person who uses the Site in order to rent a Property.
      </p>
      <p>
        "Buyer" refers to any person who uses the Site in order to purchase a Property.
      </p>
      <p>
        "Listing" means a property shown on the Site.
      </p>
      <p>
        "Content" refers to any text, graphics or images on the Site.
      </p>

      <h2>
        2. MODIFICATION
      </h2>
      <p>
        2.1 We reserve the right to make any changes and/or additions to the Site and these terms and conditions 
        without prior notification to you. You are required to check these terms and conditions each time 
        you use the Site, as they may change from time to time. These terms and conditions replace any 
        previous published terms related to the use of the Site.
      </p>

      <h2>
        3. PLATFORM
      </h2>
      <p>
        3.1 The Site is an online portal to assist Renters and/or Buyers to Owners and/or Agents. 
        The Site is provided on an as-is basis. We are not the agent of any Owner/Agent listed on the Site, 
        or of any Renter/Buyer.
      </p>
      <p>
        3.2 We are not a party, either ourselves or on behalf of any Owner/Agent or the Renter/Buyer, 
        in any transaction resulting from use of the site, or any dispute arising from any such transaction. 
        Owners/Agents and Renters/Buyers contract directly with each other and should communicate and deal 
        with each other directly to resolve any problems or disputes.
      </p>
      <p>
        3.3 Any communications between, including any transactions between, Renters/Buyers and Owner/Agent 
        are conducted entirely at the parties’ own risk. Without limiting any other clause of these terms 
        and conditions, you acknowledge that the Site takes no responsibility or liability for any misconduct 
        of any of its users including, without limitation, misrepresentation of properties including but not 
        necessarily limited to any fraudulent behaviour, and that the Site gives no undertakings, representations, 
        or warranties in relation to the accuracy of information on the website. Your use of the 
        Site is entirely at your own risk.
      </p>
      <p>
        3.4 You must not use the Site to post fale or misleading information, or to make false or 
        fraudulent enquiries.
      </p>
      <p>
        3.5 The Site reserves the right to amend or remove information published on or through the Site if 
        we consider it incorrect or misleading, or for any reason, at our unfettered discretion.
      </p>
      <p>
        3.6 While our Site shows probable availability of some rental Properties, it is not a guarantee 
        that a specific Property is available at a certain time, or that the details posted about the 
        Properties are accurate. Renters must always check directly with the Owner/Agent.
      </p>
      <p>
        3.7 Owners/Agents may have particular requirements or restrictions on their rental Properties. 
        If so, these are between the parties, and the Site is under no obligation to assist in enforcing 
        such arrangements.
      </p>
      <p>
        3.8 by accessing or using the Site, you are accepting these terms and conditions and you represent 
        and warrant that you have the right, authority, and capacity to enter into this agreement. 
      </p>
      <p>
        3.9 You may not access or use the site or accept these terms and conditions if you are not at 
        least 18 years old. 
      </p>
      <p>
        3.10 If you do not agree with all of the provisions of this Agreement, do not access and/or use the Site. 
        Continuing to use the Site in any capacity shall be deemed to be an acceptance by you of all 
        of the terms of this Agreement.
      </p>

      <h2>
        4. BLUEMOUNTAINACCOMMODATIONS PAYMENTS
      </h2>
      <p>
        4.1 The Site works with a company called Stripe.com in order to help to accommodate payments between 
        the parties. We reserve the right to receive a commission from Stripe for any such transactions 
        without liability accruing to us related to such transaction.
      </p>
      <p>
        4.2 We charge a service fee of 4% payable by Renters or Purchasers, as the case may be, for all 
        transactions based upon listings on the Site, whether or not Stripe is utilized. You may not 
        attempt to circumvent the Site in order to transact independently outside of the Site.
      </p>
      <p>
        4.3 The service fee is non-refundable, regardless of the Owner/Agent's cancellation policy.
      </p>
      <p>
        4.4 Before making or accepting any payments on the Site, or through Stripe, you must first review and agree to Stripe’s terms and conditions, 
        which are located at <a href='https://stripe.com/en-ca/connect-account/legal' rel="noopener noreferrer"
        target='_blank' title='Stripe Connected Account Agreement'>https://stripe.com/en-ca/connect-account/legal</a> and may be modified from time to time.
      </p>
      <p>
        4.5 We are not a party to any payment transaction between parties, even if we receive a commission in connection 
        with any payment transaction.
      </p>
      <p>
        4.6 Renters are responsible for ensuring they have read the Owner/Agent's terms related to the Property and 
        any such transaction prior to making a payment.
      </p>
      <p>
        4.7 You understand and agree that we may share your personal information in order to facilitate your use of Stripe, 
        and wherever required in order to provide the services on the Site. Please also review and agree to our 
        Privacy Policy before using the Site.
      </p>

      <h2>
        5. DISCLAIMER
      </h2>
      <p>
        5.1 We are not an owner or operator of any Properties shown on the Site. We are not a contracting agent or representative 
        of any advertiser. We encourage Renters/Buyers to communicate directly with the Owner/Agent regarding any rentals or 
        listings made via the Site. We do not make any recommendations or endorsements of listed Properties or Renters of 
        the Site and assumes no liability in respect of the condition of any listed Property on our Site.
      </p>
      <p>
        5.2 The Site does not give any warranties of any kind, whether express or implied, in relation to the Site, the 
        service provided by the Site, or your use of our website, including (without limitation) the availability, 
        accuracy or completeness of the Site and the information on it, or the quality or suitability of any accommodation, 
        product or service featured or made available on the website.
      </p>
      <p>
        We cannot and do not fully control the content contained in any listings and the condition, legality or suitability 
        of any properties. Bluemountainaccommodations is not responsible for and disclaims any and all liability 
        related to any and all listings and properties. Accordingly, any booking or purchase is made at the Renter or Purchaser’s 
        own risk.
      </p>

      <h2>
        6. REVIEWS
      </h2>
      <p>
        6.1 The Site is not responsible for any of the opinions expressed in the reviews. By posting a review you agree to take 
        full legal responsibility and liability for your review and to indemnify the Site from all liability, loss, damages, 
        costs or expenses suffered by the Site in connection with your review.
      </p>
      <p>
        6.2 The Site reserves the right to remove any reviews that it considers inappropriate or in breach of these 
        terms and conditions.
      </p>
      <p>
        6.3 You must not market or export any reviews on the Site to any other venue or website.
      </p>

      <h2>
        7. RESOLUTION OF DISPUTES
      </h2>
      <p>
        7.1 As the Site is not a party to any rental transaction between renter and owner/agent, you agree not to involve, 
        or attempt to involve, us in any dispute or in the resolution of disputes that arise between the parties under 
        any circumstances.
      </p>

      <h2>8. GENERAL</h2>
      <p>
        8.1 These Terms and Conditions are subject to the laws of Saskatechewan and Canada. You expressly recognize that any 
        action you initiated by yourself, BlueMountainAccommodations or any other party to enforce these Terms of Service 
        or any other component relative to the site must be exclusively presented to a court of competent 
        jurisdiction in Saskatchewan.
      </p>
      <p>
        8.2 This Agreement, and the related Privacy Policy, constitute the entire agreement between you and us. 
        This Agreement sets forth your exclusive remedies with respect to your use of the Site. If any provision or 
        portion of this Agreement is held to be invalid or unenforceable under applicable law, then it will be 
        reformed interpreted to accomplish the objectives of such provision to the greatest extent possible, 
        and all remaining provisions will continue in full force and effect.
      </p>

      <p>Thank you and welcome to BM Accommodations! </p>

    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
