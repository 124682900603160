import React from 'react';
import PropTypes from 'prop-types';

const IconLogo = props => {
  const { className, ...rest } = props;

  return (
    <img
      className={className}
      src="/static/icons/logo.svg"
      // alt={config.siteTitle}
      {...rest}
      // width="21"
      height="25"
      
      
    />

    
  );
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
