import React from 'react';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';
import { DATE_TYPE_DATE, propTypes } from '../../util/types';
import { dateFromAPIToLocalNoon } from '../../util/dates';

import css from './BookingBreakdown.css';

const BookingPeriod = props => {
  const { startDate, endDate, dateType, endDateExist } = props;

  const timeFormatOptions =
    dateType === DATE_TYPE_DATE
      ? {
          weekday: 'long',
        }
      : {
          weekday: 'short',
          hour: 'numeric',
          minute: 'numeric',
        };

  const dateFormatOptions = {
    month: 'short',
    day: 'numeric',
  };

  const endDateRow = <div className={css.bookingPeriodSectionRigth}>
    <div className={css.dayLabel}>
      <FormattedMessage id="BookingBreakdown.bookingEnd" />
    </div>
    <div className={css.dayInfo}>
      <FormattedDate value={endDate} {...timeFormatOptions} />
    </div>
    <div className={css.itemLabel}>
      <FormattedDate value={endDate} {...dateFormatOptions} />
    </div>
  </div>;

  return (
    <>
      <div className={css.bookingPeriod}>
        <div className={css.bookingPeriodSection}>
          <div className={css.dayLabel}>
            <FormattedMessage id="BookingBreakdown.bookingStart" />
          </div>
          <div className={css.dayInfo}>
            <FormattedDate value={startDate} {...timeFormatOptions} />
          </div>
          <div className={css.itemLabel}>
            <FormattedDate value={startDate} {...dateFormatOptions} />
          </div>
        </div>
        {endDateExist !== 'false' && endDate.getDate() !== startDate.getDate() ? endDateRow : null}
      </div>
    </>
  );
};

const LineItemBookingPeriod = props => {
  const { booking, dateType , endDateExist, listing } = props;

  localStorage.setItem('endDateExist', `${!!endDateExist}`);

  // Attributes: displayStart and displayEnd can be used to differentiate shown time range
  // from actual start and end times used for availability reservation. It can help in situations
  // where there are preparation time needed between bookings.
  // Read more: https://www.sharetribe.com/api-reference/#bookings
  const { start, end, displayStart, displayEnd } = booking.attributes;
  const localStartDate = dateFromAPIToLocalNoon(displayStart || start);
  const localEndDateRaw = dateFromAPIToLocalNoon(displayEnd || end);

  const endDay = localEndDateRaw;
  const displayedEndDate = Object.assign(endDay);
  listing && listing.attributes.publicData.category !== 'accommodations' && displayedEndDate.setDate(displayedEndDate.getDate() - 1);

  return (
    <>
      <div className={css.lineItem}>
        <BookingPeriod startDate={localStartDate} endDate={displayedEndDate} dateType={dateType} endDateExist={endDateExist} />
      </div>
    </>
  );
};
LineItemBookingPeriod.defaultProps = { dateType: null };

LineItemBookingPeriod.propTypes = {
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,
};

export default LineItemBookingPeriod;
