import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export default [
  {
    id: 'default-mountains',
    predictionPlace: {
      address: 'Blue Mountains, Canada',
      bounds: new LatLngBounds(new LatLng(44.951837, -80.2664921), new LatLng(44.372739, -80.61282779999999)),
    },
  },
];
