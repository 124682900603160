import React from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  
} from '../../components';

import css from './FaqPage.css';


const FaqPage = () => {

  // prettier-ignore
  return (
    <StaticPage
      title="FAQS | Blue Mountain Accommodations"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'FaqPage',
        description: 'Blue Mountain Accommodations frequently asked questions, everything you need to know about listing your Blue Mountain vacation rental on Blue Mountain Accommodations.',
        name: 'FAQ Page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1>Host FAQS</h1>
          <br/>
          <h2 className={css.sectionTitle}>Who can be an Blue Mountain Accommodations host?</h2>
          <p>
            It's easy to become an Blue Mountain Accommodations host and it's always free to create a listing. 
            Entire chalets and homes, condos are just a few of the properties hosts have listed on Blue Mountain Accommodations.
          </p>
          
          <h2 className={css.sectionTitle}>What is required of guests before booking?</h2>
          <p>
            Blue Mountain Accommodations requirements for guests include the following: Full name, Email address, Phone number, 
            Agreement to your House Rules, Payment information.You may also require guests to provide ID before booking your rental.
          </p>

          <h2 className={css.sectionTitle}>How much does it cost to list my rental?</h2>
          <p>
            Signing up for Blue Mountain Accommodations and listing your rental is completely free.
          </p>

          <h2 className={css.sectionTitle}>What protection do I have against property damage?</h2>
          <p>
            Once a guest books, you will have their full contact information and can make your own arrangements to charge them a security deposit.
            Any security deposit that you charge guests should be clearly stated in your listing so the guest is aware prior to making a booking.
          </p>

          <h2 className={css.sectionTitle}>How much should I charge my guests to stay at my place?</h2>
          <p>
            How much you charge your guests is entirely up to you.If you need some guidance on how much to charge you can 
            always have a look at similar places to yours that are already listed on the site to give you an idea of 
            what you should charge your guests.
          </p>

          <h2 className={css.sectionTitle}>When do I get paid?</h2>
          <p>
            You will receive payment as soon as the guest has guest checked out.Please note you will need a Stripe account in order to receive payments.
          </p>


        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default FaqPage;
