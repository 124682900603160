import axios from 'axios';
import qs from 'querystring';
import { storableError } from '../util/errors';

// currency exchange amount
// ================ Action types ================ //

export const SET_REMOTE_CALENDAR_REQUEST = 'app/remoteCalendar/SET_REMOTE_CALENDAR_REQUEST';
export const SET_REMOTE_CALENDAR_SUCCESS = 'app/remoteCalendar/SET_REMOTE_CALENDAR_SUCCESS';
export const SET_REMOTE_CALENDAR_ERROR = 'app/remoteCalendar/SET_REMOTE_CALENDAR_ERROR';

// ================ Reducer ================ //

const initialState = {
  remoteCalendarLink: null,
  setRemoteCalendarInProgress: false,
  setRemoteCalendarSuccess: false,
  setRemoteCalendarError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case SET_REMOTE_CALENDAR_REQUEST:
      return {
        ...state,
        setRemoteCalendarProgress: true,
        setRemoteCalendarSuccess: false,
        setRemoteCalendarError: null,
      };
    case SET_REMOTE_CALENDAR_SUCCESS:
      return {
        ...state,
        remoteCalendarLink: payload,
        setRemoteCalendarInProgress: false,
        setRemoteCalendarSuccess: true,
        setRemoteCalendarError: null,
      };
    case SET_REMOTE_CALENDAR_ERROR:
      return {
        ...state,
        setRemoteCalendarInProgress: false,
        setRemoteCalendarSuccess: false,
        setRemoteCalendarError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setRemoteCalendarRequest = () => ({ type: SET_REMOTE_CALENDAR_REQUEST });
export const setRemoteCalendarSuccess = data => ({ type: SET_REMOTE_CALENDAR_SUCCESS, payload: data });
export const setRemoteCalendarError = error => ({ type: SET_REMOTE_CALENDAR_ERROR, payload: error, error: true });

// ================ Thunks ================ //

const API_URL_ALT = process.env.REACT_APP_API_URL_ALT;
//const API_URL_ALT = 'https://staging.bluemountainaccommodations.com';

export const setRemoteCalendar = (listingId, calendarUrl) => dispatch => {
  dispatch(setRemoteCalendarRequest());

  const params = {
    listingId,
    url: calendarUrl
  };

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  };

  return axios
    .post(`${API_URL_ALT}/add-calendar-url`, qs.stringify(params), config)
    .then(function(response) {
      dispatch(setRemoteCalendarSuccess(response.data));
      return response.data;
    })
    .catch(e => {
      dispatch(setRemoteCalendarError(storableError(e)));
    });
};
